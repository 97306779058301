$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
@use "../poloVariables" as polo;

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

$office-card-min-height: 270px;

// Custom containers to restrict widths.
.map-container, .list-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.map-wrapper {
  padding-top: 20px;
  padding-bottom: 0;

  .map-container {
    .map {
      height: 492px;
    }

    #network-map-overlay {
      position: absolute;
      display: none; // Displayed by JavaScript.
      top: 16px;
      left: 16px;
      z-index: 100001;
      background: white;
      width: 50vw;
      max-width: 360px;
      padding: polo.$card-spacer-y polo.$card-spacer-x;
      border: polo.$card-border-width solid polo.$card-border-color;
      border-radius: polo.$card-border-radius;
      box-shadow: polo.$card-box-shadow;

      .office-card-image-wrapper {
        display: none;
      }

      .office-btn {
        position: relative;
        text-align: right;
        width: 100%;
        bottom: unset;
        right: unset;

        .btn {
          margin-top: 5px;
        }
      }

    }
  }
}

.list-wrapper {
  padding: 4.57rem 0;

  @include media-breakpoint-down(md) {
    .tab-content {
      h2 {
        margin-bottom: 0;
      }

      > .tab-pane {
        display: block;
        opacity: 1;
      }
    }
  }

  .continent-tabs {
    background-image: url("../../images/mappa.png");
    background-size: contain;

    > .nav-tabs {
      justify-content: center;
    }

    .country-wrapper {
      margin-top: $grid-gutter-width;

      &:not(:last-child) {
        border-bottom: 3px rgba(181, 187, 202, 0.50) solid;
      }

      .office-card {
        min-height: $office-card-min-height;
      }
    }
  }

  // Removing background and border for the accordion.
  .accordion .accordion-item {
    background-color: transparent;
    border: none;
  }
}

.office-card-body {
  display: flex;
  // The percentages in here must add to 100.
  gap: 4%;

  .office-card-info {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 64%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .office-city {
      color: polo.$black;
      font-size: 2.286rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.011rem;
    }

    .office-name {
      color: polo.$black;
      font-size: 1.428rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.007rem;
      text-transform: uppercase;
    }

    .office-address {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      color: polo.$gray;
      font-size: 1.142rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.005rem;

      img {
        max-height: 16px;
        position: absolute;
        top: 2px;
      }

      span {
        color: #9EA4B5;
        padding-left: 25px;
      }
    }

    .office-btn {
      margin-top: auto;
      align-self: flex-end;

      .btn {
        padding: 10px;
        margin-bottom: 0;
      }
    }
  }

  .office-card-image-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32%;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;

    .office-card-info, .office-card-image-wrapper {
      flex-basis: 100%;
    }

    .office-card-info {
      .office-city {
        padding-top: 5px;
        font-size: 1.854rem; //26px
      }
    }

    .office-card-image-wrapper {
      height: 140px;
      background-size: cover;
      background-position: center;
    }

  }
}
