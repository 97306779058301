$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
@use "../poloVariables" as polo;
@use "../frontend/_colors.scss" as colors;

.slider-container {
  position: relative;

  .flickity-page-dots, .flickity-button {
    display: none;
  }

  .bg-overlay {
    opacity: 0.5;
    &[data-style="11"] {
      background: linear-gradient(90deg, rgb(2, 47, 94) 0%, #72a82b 100%);
    }
  }

  .slide-caption {
    position: absolute;
    top: 100px;
    left: 50px;

    > * {
      text-align: left;
    }

    h1 {
      display: none;
    }

    h2 {
      font-family: Barlow, sans-serif;
      font-size: 96px;
      font-weight: 800;
      line-height: 115px;
      text-transform: uppercase;

      &:after {
        content: unset;
      }
    }

    h3 {
      font-family: Barlow, sans-serif;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
    }

    @media (max-width: 767px) {

      h2 {
        font-size: 40px;
        line-height: 48px;
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    .inspiro-slider {
      height: 50vh;
    }
  }

  .shape-divider {
    bottom: -1px;
  }
}

#animation {
  width: 100vw;
  height: 400px;

  > * {
    width: 100vw;
    height: 400px;
  }
}

.services {
  background: transparent;
  z-index: 10;

  @media (min-width: 992px) {
    .oi-expandable-wrapper.squared .oi-expandable-wrapper-row {
      width: calc(469px* 2);
    }
  }

  @media (min-width: 1200px) {
    .oi-expandable-wrapper.squared .oi-expandable-wrapper-row {
      margin: 0 !important;
      width: calc(600px* 2);
    }
  }
}

.network {
  padding-top: 200px;
  padding-bottom: 200px;
  margin-top: -200px;
  background-color: #7b92d24d;
  background-image: url('../../images/background_contacts_page.png');
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  min-height: 500px;

  p {
    padding-right: 70px;
    margin-bottom: 48px;
  }

  .map {
    width: 50vw;
    position: absolute;
    right: 0;
    top: 190px;

    @media (max-width: 991px) {
      position: relative;
      width: 100vw;
      min-height: 460px;
      top: 40px;
    }
  }

  .ph {
    padding: 0 10vw;

    @media (max-width: 767px) {
      padding: 0 5vw;
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 40px;
  }

  @media (max-width: 767px) {
    .map {
      margin-top: 50px;
      height: 90vw;
    }
  }
}

.counters-section {
  background-image: url('../../images/background-counter-network.jpg');
  background-size: cover;
}

.history {
  background-image: url('../../images/background-history.png');
  background-size: 100vw;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  padding-top: 115px;
  padding-bottom: 92px;

  .order-lg-last {
    > div {
      padding-top: 35px;
    }
  }

  @media (max-width: 991px) {
    background-image: url('../../images/background-history-2.png');
  }

  @media (max-width: 850px) {
    background-image: url('../../images/background-history-3.png');
  }

  @media (max-width: 575px) {
    background-image: url('../../images/background-history-4.png');
    padding-bottom: 49px;
  }

  #slider-logo.inspiro-slider {
    height: 500px;
    padding-bottom: 110px;
    text-align: center;
    overflow: visible;

    .flickity-viewport {
      height: 390px;

      .slide {
        padding-left: 50px;

        img {
          border-radius: 8px;
        }
      }
    }

    .flickity-button {
      display: none;
    }

    .flickity-page-dots {
      position: absolute;
      bottom: 50px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 20%, polo.$secondary 20%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);
      height: 20px;
      border-radius: 10px;
      left: -50px;

      .dot {
        margin: 0 80px;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px polo.$primary;
        overflow: visible;
        position: relative;
        background-color: polo.$primary;

        .dcs-logo, .year {
          position: absolute;
          display: none;
        }

        .year {
          font-family: Barlow, sans-serif;
          font-weight: 800;
          font-size: 40px;
          color: polo.$primary;
          bottom: -45px;
          left: -35px;
        }

        .dcs-logo {
          width: 196px;
          height: 98px;
          padding: 3.5px 13px;
          background-color: polo.$white;
          bottom: 40px;
          left: -87px;
          border-radius: 20px;
          text-align: center;
          justify-content: center;
          align-items: center;

          box-shadow: 0 0 10px #0008;

          img {
            max-width: 100%;
            max-height: 91px;
            margin: 0;
          }

          &:before {
            content: ' ';
            position: absolute;
            border: 20px solid transparent;
            border-top-color: polo.$white;
            bottom: -38px;
            left: 74px;
          }
        }

        &.is-selected {
          background-color: polo.$success;
          border: 2px solid polo.$white;
          box-shadow: inset 0 0 0 2px polo.$white;

          &:after {
            background-color: polo.$success;
          }

          .dcs-logo, .year {
            display: flex;
          }
        }

        &:after {
          background-color: polo.$primary;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (min-width: 1200px) and (max-width: 1270px) {
      .flickity-page-dots {
        .dot {
          &:first-child {
            .dcs-logo {
              left: -57px;

              &:before {
                content: ' ';
                border: 20px solid transparent;
                border-top-color: polo.$white;
                bottom: -38px;
                left: 47px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1199px) {
      .flickity-viewport {
        .slide {
          padding-left: 30px;
        }
      }
      .flickity-page-dots {
        left: -30px;

        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 10%, polo.$secondary 10%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);
      }
    }

    @media (min-width: 992px) and (max-width: 1100px) {
      .flickity-page-dots {
        .dot {
          &:first-child {
            .dcs-logo {
              left: -27px;

              &:before {
                content: ' ';
                border: 20px solid transparent;
                border-top-color: polo.$white;
                bottom: -38px;
                left: 17px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .flickity-viewport {
        .slide {
          padding-left: 50px;
        }
      }
      .flickity-page-dots {
        left: -50px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 22%, polo.$secondary 22%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);
      }
    }

    @media (max-width: 767px) {
      height: 450px;

      .flickity-viewport {
        .slide {
          padding-left: 20px;
        }
      }
      .flickity-page-dots {
        left: -30px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 12%, polo.$secondary 12%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);
      }
    }

    @media (max-width: 615px) {
      .flickity-page-dots {
        .dot {
          &:first-child {
            .dcs-logo {
              left: -57px;

              &:before {
                content: ' ';
                border: 20px solid transparent;
                border-top-color: polo.$white;
                bottom: -38px;
                left: 47px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 575px) {
      height: 80vw;

      .flickity-viewport {
        height: calc(80vw - 110px);

        .slide {
          padding-left: 0;
          border-radius: 8px;
          overflow: visible;
          max-height: 100%;

          .container {
            margin: 0;
            padding: 0;
            max-height: 100%;

            img {
              max-height: calc(80vw - 110px);
            }
          }
        }
      }

      .flickity-page-dots {
        left: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 23%, polo.$secondary 24%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);

        .dot {
          margin: 0 10vw;

          &:last-child {
            .dcs-logo {
              left: -120px;

              &:before {
                content: ' ';
                border: 20px solid transparent;
                border-top-color: polo.$white;
                bottom: -38px;
                left: 110px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 425px) {
      .flickity-page-dots {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 20%, polo.$secondary 24%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);
      }
    }
    @media (max-width: 375px) {
      .flickity-page-dots {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 18.5%, polo.$secondary 19.5%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);
      }
    }
    @media (max-width: 320px) {
      .flickity-page-dots {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 17%, polo.$secondary 17%, polo.$secondary 50%, rgba(255, 255, 255, 0.0) 99%);

      }
    }
  }

  .dcs-timeline-element-container {
    margin: 40px 0;
    background-image: url('../../images/wave.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: calc((100vw - 1222px) / 2 + 100px) 35px;

    h4 {
      width: 75%;
      margin: 0 auto 0 0;
      text-align: center;
      font-size: 48px;
      font-family: Barlow, sans-serif;
      font-weight: 800;
      color: polo.$primary;
      @media (max-width: 1023px) {
        font-size: 36px;
      }
      @media (max-width: 767px) {
        width: 50vw;
      }
    }

    .card {
      width: 75%;
      margin: 0 auto 0 0;
      height: 150px;
      border-radius: 20px;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff88;

      @media (max-width: 991px) {
        height: 130px;
      }

      @media (max-width: 767px) {
        width: 50vw;
        margin-right: 10vw;
      }

      img {
        max-width: 100%;
        max-height: 130px;
        margin: 0;

        @media (max-width: 991px) {
          max-height: 110px;
        }

        @media (max-width: 767px) {
          max-width: 45vw;
        }
      }
    }

    > div {
      .row {
        > div {
          &:nth-child(even) {
            .logos {
              padding-top: 80px;
            }
          }
        }
      }
    }

    @media (max-width: 1199px) {
      background-position: calc((100vw - 960px) / 2 + 100px) 50px;
    }
    @media (max-width: 991px) {
      background-position: 50px 50px;
    }
    @media (max-width: 767px) {
      background-image: url('../../images/wavev.svg');
      background-position: center 50px;
      margin-bottom: 0;
      padding-bottom: 80px;

      > div {
        .row {
          > div {
            .logos {
              padding-top: unset !important;

              .card, h4 {
                margin: 0 auto 0 5%;
              }
            }

            &:nth-child(odd) {
              .logos {
                .card, h4 {
                  margin: 0 5% 0 auto;
                }
              }
            }

            &:first-child {
              .logos {
                .card, h4 {
                  margin: 0 auto
                }
              }
            }
          }
        }
      }
    }
  }


  .space {
    height: 120px;
    @media (max-width: 767px) {
      height: 200px;
    }
  }

  #dcs-timeline-wrapper {
    overflow-x: visible;

    #dcs-timeline.horizontal {
      background: transparent !important;
      width: 110vw;

      @media (max-width: 767px) {
        width: 170vw;
      }

      .dcs-timeline-element-container {
        background-image: url('../../images/wave.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 35px;

        .dcs-timeline-element {
          max-width: 40vw;
          display: inline-block;

          @media (max-width: 767px) {
            max-width: 60vw;
          }

          &:last-child {
            width: 30vw;
            @media (max-width: 767px) {
              width: 50vw;
            }
          }

          h4 {
            width: 30vw;
            text-align: center;
            font-size: 64px;
            font-family: Barlow, sans-serif;
            font-weight: 800;
            color: polo.$primary;
            @media (max-width: 1023px) {
              font-size: 48px;
            }
            @media (max-width: 767px) {
              width: 50vw;
            }
          }

          .card {
            width: 30vw;
            margin-right: 20vw;
            height: 230px;
            border-radius: 20px;
            text-align: center;

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff88;

            @media (max-width: 1023px) {
              height: 160px;
            }
            @media (max-width: 767px) {
              width: 50vw;
              margin-right: 10vw;
            }

            img {
              max-width: 25vw;
              max-height: 170px;
              margin: 0;

              @media (max-width: 1023px) {
                max-height: 100px;
              }
              @media (max-width: 767px) {
                max-width: 45vw;
              }
            }
          }
        }
      }
    }
  }
}

#floor {
  z-index: 10;
  margin-top: -80px;
  @media (max-width: 767px) {
    margin-top: -10px;
  }
}

